<template>
<div>
  <br>
  
  <div class="pull-right">
    Wersja językowa:
    <router-link :to="{name: 'Renovation', params: {lang: 'pl'}}" class="btn btn-default">PL</router-link>
    <router-link :to="{name: 'Renovation', params: {lang: 'de'}}" class="btn btn-default">DE</router-link>
    <router-link :to="{name: 'Renovation', params: {lang: 'en'}}" class="btn btn-default">EN</router-link>
  </div>
  
  <div class="clearfix"></div>
  
  <form class="form-horizontal" @submit.prevent="submit">
    <fieldset>
      <legend>Dane ogólne</legend>
      
      <form-group label="Nagłówek" :error="errors['header']">
        <text-input v-model="renovation.header"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['description']">
        <textarea-input v-model="renovation.description" rows="6"></textarea-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Renowacja 1</legend>
      
      <form-group label="Tytuł" :error="errors['firstTitle']"
                  help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
        <text-input v-model="renovation.firstTitle"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['firstDescription']">
        <textarea-input v-model="renovation.firstDescription" rows="6"></textarea-input>
      </form-group>
      
      <form-group label="Zdjęcia" :error="errors['firstPhotos']">
        <media-input v-model="renovation.firstPhotos" multiple></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Renowacja 2</legend>
      
      <form-group label="Tytuł" :error="errors['secondTitle']"
                  help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
        <text-input v-model="renovation.secondTitle"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['secondDescription']">
        <textarea-input v-model="renovation.secondDescription" rows="6"></textarea-input>
      </form-group>
      
      <form-group label="Zdjęcia" :error="errors['secondPhotos']">
        <media-input v-model="renovation.secondPhotos" multiple></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Renowacja 3</legend>
      
      <form-group label="Tytuł" :error="errors['thirdTitle']"
                  help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
        <text-input v-model="renovation.thirdTitle"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['thirdDescription']">
        <textarea-input v-model="renovation.thirdDescription" rows="6"></textarea-input>
      </form-group>
      
      <form-group label="Zdjęcia" :error="errors['thirdPhotos']">
        <media-input v-model="renovation.thirdPhotos" multiple></media-input>
      </form-group>
    </fieldset>
    
    <submit-button :submitting="submitting"></submit-button>
  </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import Api from './../api'
import flash from './../Flash'
import Ckeditor from 'vue-ckeditor2'
import { flatten } from '../helpers'

export default {
  props: {
    lang: {type: String}
  },
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api,
    Ckeditor
  },
  data () {
    return {
      renovation: {
        header: '',
        description: '',
        firstTitle: '',
        firstDescription: '',
        firstPhotos: [],
        secondTitle: '',
        secondDescription: '',
        secondPhotos: [],
        thirdTitle: '',
        thirdDescription: '',
        thirdPhotos: []
      },
      errors: [],
      submitting: false
    }
  },
  methods: {
    submit () {
      this.errors = []
      this.submitting = true
      let renovation = {...this.renovation}
      flatten(renovation, 'files', ['firstPhotos', 'secondPhotos', 'thirdPhotos'])
      Api.renovation.update(renovation.id, renovation).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
    },
    updateSuccess ({body}) {
      this.instrument = body
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'Renovation',
        params: {
          lang: this.lang
        }
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      Api.renovation.get(this.lang).then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.renovation = body[0]
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  created () {
    this.fetchData()
  }
}
</script>
