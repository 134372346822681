<template>
<div>
  <form class="form-horizontal" @submit.prevent="submit">
    <fieldset>
      <legend>Dane ogólne</legend>
      
      <form-group label="Tytuł" :error="errors['header']">
        <text-input v-model="news.header"></text-input>
      </form-group>
      
      <form-group label="Treść" :error="errors['content']">
        <textarea-input v-model="news.content" rows="6"></textarea-input>
      </form-group>
      
      <form-group label="Data" :error="errors['date']">
        <date-picker v-model="news.date"></date-picker>
      </form-group>
    </fieldset>
  
    <fieldset>
      <legend>Zdjęcia</legend>
    
      <form-group :error="errors['images']">
        <media-input v-model="news.images" multiple></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Pliki</legend>
      
      <form-group :error="errors['files']">
        <media-input v-model="news.files" multiple></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Miniatura</legend>
      
      <form-group :error="errors['thumbnail']">
        <media-input v-model="news.thumbnail"></media-input>
      </form-group>
    </fieldset>
    
    <submit-button :submitting="submitting"></submit-button>
  </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import DatePicker from './DatePicker.vue'
import Api from './../api'
import flash from './../Flash'
import Ckeditor from 'vue-ckeditor2'
import { flatten } from '../helpers'

export default {
  props: {
    id: {type: [String, Number]}
  },
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api,
    Ckeditor,
    DatePicker
  },
  data () {
    return {
      submitting: false,
      news: {
        header: '',
        content: '',
        thumbnail: null,
        date: null,
        images: [],
        files: []
      },
      errors: []
    }
  },
  methods: {
    submit () {
      this.errors = []
      this.submitting = true
      let news = {...this.news}
      flatten(news, 'files', ['thumbnail', 'images', 'files'])
      if (this.id) {
        Api.news.update(this.id, news).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
      } else {
        Api.news.post(news).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
      }
    },
    updateSuccess ({body}) {
      this.setData(body)
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'EditNews',
        params: {
          id: this.news.id
        }
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      if (this.id) {
        Api.news.get(this.id).then(this.fetchSuccess)
      }
    },
    fetchSuccess ({body}) {
      this.setData(body)
    },
    setData (body) {
      this.news = body
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
