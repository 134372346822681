<template>
<textarea class="form-control" @input="$emit('input', $event.target.value)" v-text="value"></textarea>
</template>

<script>
export default {
  props: {
    value: {required: true}
  }
}
</script>
