<template>
<input ref="datepicker" :value="value" class="form-control">
</template>
<script>
import $ from 'jquery'
import 'bootstrap-datepicker'
// import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pl.min'

export default {
  props: {
    value: {required: true}
  },
  mounted () {
    $(this.$refs.datepicker).datepicker({
      format: 'yyyy-mm-dd',
      language: 'pl',
      autoclose: true
    }).on('changeDate', () => {
      this.$emit('input', this.$refs.datepicker.value)
    })
  },
  destroyed () {
    $(this.$refs.datepicker).datepicker('destroy')
  }
}
</script>
<style>
@import './../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min.css';
</style>
