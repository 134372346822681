<template>
<ul class="nav nav-pills nav-stacked nav-bordered row">
  <router-link tag="li" role="presentation" :to="{ name: 'Homepage' }">
    <a href="">Strona główna</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'AboutInstruments' }">
    <a href="">O instrumentach</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'ListOfInstruments' }">
    <a href="">Lista instrumentów</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'Renovation', params: {lang: 'pl'} }">
    <a href="">Renowacja</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'About' }">
    <a href="">O Schimmel</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'ListOfNews' }">
    <a href="">Aktualności</a>
  </router-link>
  <router-link tag="li" role="presentation" :to="{ name: 'Contact' }">
    <a href="">Kontakt</a>
  </router-link>
</ul>
</template>
<script>
import moment from 'moment'

export default {
  data () {
    return {
      defaultDate: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    recordsRoute () {
      return {
        name: 'Records',
        params: {
          id: this.defaultDate
        }
      }
    }
  }
}
</script>
