<template>
<div>
    <br>

    <div class="pull-right">
        Wersja językowa:
        <router-link :to="{name: 'Process', params: {lang: 'pl'}}" class="btn btn-default">PL</router-link>
        <router-link :to="{name: 'Process', params: {lang: 'de'}}" class="btn btn-default">DE</router-link>
        <router-link :to="{name: 'Process', params: {lang: 'en'}}" class="btn btn-default">EN</router-link>
    </div>

    <div class="clearfix"></div>

    <form class="form-horizontal" @submit.prevent="submit">
        <fieldset>
            <legend>Dane ogólne</legend>

            <form-group label="Nagłówek" :error="errors['header']">
                <text-input v-model="process.header"></text-input>
            </form-group>
        </fieldset>

        <fieldset>
            <legend>Krok 1</legend>

            <form-group label="Tytuł" :error="errors['firstTitle']"
                        help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
                <text-input v-model="process.firstTitle"></text-input>
            </form-group>

            <form-group label="Opis" :error="errors['firstDescription']">
                <ckeditor v-model="process.firstDescription" :config="config" id="editor1"></ckeditor>
            </form-group>

            <form-group label="Zdjęcie przed" :error="errors['firstPhotoBefore']">
                <media-input v-model="process.firstPhotoBefore"></media-input>
            </form-group>

            <form-group label="Zdjęcie po" :error="errors['firstPhotoAfter']">
                <media-input v-model="process.firstPhotoAfter"></media-input>
            </form-group>
        </fieldset>

        <fieldset>
            <legend>Krok 2</legend>

            <form-group label="Tytuł" :error="errors['secondTitle']"
                        help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
                <text-input v-model="process.secondTitle"></text-input>
            </form-group>

            <form-group label="Opis" :error="errors['secondDescription']">
                <ckeditor v-model="process.secondDescription" :config="config" id="editor2"></ckeditor>
            </form-group>

            <form-group label="Zdjęcie przed" :error="errors['secondPhotoBefore']">
                <media-input v-model="process.secondPhotoBefore"></media-input>
            </form-group>

            <form-group label="Zdjęcie po" :error="errors['secondPhotoAfter']">
                <media-input v-model="process.secondPhotoAfter"></media-input>
            </form-group>
        </fieldset>

        <fieldset>
            <legend>Krok 3</legend>

            <form-group label="Tytuł" :error="errors['thirdTitle']"
                        help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
                <text-input v-model="process.thirdTitle"></text-input>
            </form-group>

            <form-group label="Opis" :error="errors['thirdDescription']">
                <ckeditor v-model="process.thirdDescription" :config="config" id="editor3"></ckeditor>
            </form-group>

            <form-group label="Zdjęcie przed" :error="errors['thirdPhotoBefore']">
                <media-input v-model="process.thirdPhotoBefore"></media-input>
            </form-group>

            <form-group label="Zdjęcie po" :error="errors['thirdPhotoAfter']">
                <media-input v-model="process.thirdPhotoAfter"></media-input>
            </form-group>
        </fieldset>

        <fieldset>
            <legend>Krok 4</legend>

            <form-group label="Tytuł" :error="errors['fourthTitle']"
                        help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
                <text-input v-model="process.fourthTitle"></text-input>
            </form-group>

            <form-group label="Opis" :error="errors['fourthDescription']">
                <ckeditor v-model="process.fourthDescription" :config="config" id="editor4"></ckeditor>
            </form-group>

            <form-group label="Zdjęcie przed" :error="errors['fourthPhotoBefore']">
                <media-input v-model="process.fourthPhotoBefore"></media-input>
            </form-group>

            <form-group label="Zdjęcie po" :error="errors['fourthPhotoAfter']">
                <media-input v-model="process.fourthPhotoAfter"></media-input>
            </form-group>
        </fieldset>

        <submit-button :submitting="submitting"></submit-button>
    </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import Api from './../api'
import flash from './../Flash'
import Ckeditor from 'vue-ckeditor2'
import { flatten, config } from '../helpers'

export default {
  props: {
    lang: {type: String}
  },
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api,
    Ckeditor
  },
  data () {
    return {
      config: config,
      process: {
        header: '',
        firstTitle: '',
        firstDescription: '',
        firstPhotoBefore: null,
        firstPhotoAfter: null,
        secondTitle: '',
        secondDescription: '',
        secondPhotoBefore: null,
        secondPhotoAfter: null,
        thirdTitle: '',
        thirdDescription: '',
        thirdPhotoBefore: null,
        thirdPhotoAfter: null,
        fourthTitle: '',
        fourthDescription: '',
        fourthPhotoBefore: null,
        fourthPhotoAfter: null
      },
      errors: [],
      submitting: false
    }
  },
  methods: {
    submit () {
      this.errors = []
      this.submitting = true
      let process = {...this.process}
      flatten(process, 'files', [
        'firstPhotoBefore',
        'firstPhotoAfter',
        'secondPhotoBefore',
        'secondPhotoAfter',
        'thirdPhotoBefore',
        'thirdPhotoAfter',
        'fourthPhotoBefore',
        'fourthPhotoAfter'
      ])
      Api.process.update(process.id, process).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
    },
    updateSuccess ({body}) {
      this.process = body
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'Process',
        params: {
          lang: this.lang
        }
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      Api.process.get(this.lang).then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.process = body[0]
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  created () {
    this.fetchData()
  }
}
</script>
