<template>
<div>
  <button
    class="btn btn-default"
    type="button"
    @click.prevent="showMediaManager = true"
    v-show="showBrowse"
  >
    Wybierz
  </button>
  <media-manager
    v-if="showMediaManager"
    @close="showMediaManager = false"
    :selected="localValue"
    @select="onSelect"
    @unselect="onUnSelect"
  >
  </media-manager>
  <div v-if="!showMediaManager" class="row">
    <media-item
      v-for="(media, index) in localValue"
      :key="index"
      :media="media"
      @change="showMediaManager = true"
      @delete="onDelete"
      :item-class="itemClass"
    >
    </media-item>
  </div>
</div>
</template>
<script>
import MediaItem from './MediaItem.vue'
import MediaManager from './MediaManager.vue'

export default {
  props: {
    value: {required: true},
    multiple: {type: Boolean},
    itemClass: {type: String, default: 'col-md-4'}
  },
  data () {
    return {
      showMediaManager: false
    }
  },
  components: {
    MediaItem,
    MediaManager
  },
  methods: {
    onDelete (media) {
      if (this.multiple) {
        let currentValues = this.localValue
        currentValues.splice(currentValues.indexOf(media), 1)
        this.$emit('input', currentValues)
      } else {
        this.$emit('input', null)
      }
    },
    onSelect (media) {
      if (!this.multiple) {
        this.showMediaManager = false
        this.$emit('input', {
          filename: media.filename,
          filepath: media.filepath,
          id: media.id
        })
      } else {
        let medias = this.localValue
        medias.push(media)
        this.$emit('input', medias)
      }
    },
    onUnSelect (media) {
      if (!this.multiple) {
        this.showMediaManager = false
        this.$emit('input', null)
      } else {
        let currentValues = this.localValue
        currentValues.splice(currentValues.indexOf(media), 1)
        this.$emit('input', currentValues)
      }
    }
  },
  computed: {
    localValue () {
      if (!(this.value instanceof Array)) {
        if (this.value) {
          return [this.value]
        }
        return []
      }
      return this.value
    },
    showBrowse () {
      return !this.showMediaManager && this.valueIsEmpty
    },
    valueIsEmpty () {
      return this.localValue.length === 0
    }
  }
}
</script>
