<template>
<div>
    <h4>Kontakt</h4>

    <form-data
            :data="data"
            :submit="submit"
    >
        <template slot-scope="props">
        <form-group label="Dane firmy" :error="props.errors.companyData">
            <ckeditor v-model="props.data.companyData" :config="config" id="first"></ckeditor>
        </form-group>

        <form-group label="Telefony" :error="props.errors.phonesData">
            <ckeditor v-model="props.data.phonesData" :config="config" id="second"></ckeditor>
        </form-group>

        <form-group label="Email do wiadomości" :error="props.errors.contactEmail">
            <text-input v-model="props.data.contactEmail"></text-input>
        </form-group>
        </template>
    </form-data>
</div>
</template>
<script>
import FormData from './FormData.vue'
import SubmitButton from './SubmitButton.vue'
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import Api from './../api'
import Ckeditor from 'vue-ckeditor2'
import { config } from './../helpers'

export default {
  data () {
    return {
      config: config,
      data: {
        companyData: '',
        phonesData: '',
        contactEmail: ''
      }
    }
  },
  components: {
    Ckeditor,
    FormData,
    SubmitButton,
    FormGroup,
    TextInput
  },
  methods: {
    submit () {
      return Api.content.put('kontakt', {json: JSON.stringify(this.data)})
    },
    fetchData () {
      Api.content.get('kontakt').then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.data = JSON.parse(body.json)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
