<template>
<form class="form-horizontal" @submit.prevent="send">
    <slot :data="data" :errors="errors"></slot>
    <button class="btn btn-primary" :disabled="submitting">
        <template v-if="submitting">Zapisywanie</template>
        <template v-else>Zapisz</template>
    </button>
</form>
</template>

<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import Flash from './../Flash'

export default {
  props: {
    data: {type: Object, required: true},
    submit: {type: Function, required: true},
    successMessage: {type: String},
    errorMessage: {type: String},
    api: {type: Object}
  },
  data () {
    return {
      submitting: false,
      errors: {}
    }
  },
  components: {
    FormGroup,
    TextInput,
    Flash
  },
  methods: {
    send () {
      this.submitting = true
      this
        .submit()
        .then(this.handleSuccess, this.handleError)
        .then(() => {
          this.submitting = false
        })
    },
    handleSuccess () {
      this.errors = {}
      Flash.success(this.getSuccessMessage)
      this.$emit('success')
    },
    handleError ({status, body}) {
      if (status === 400) {
        this.errors = this.normalizeErrors(body.violations)
      }
      Flash.error(this.getErrorMessage)
    },
    normalizeErrors (errors) {
      let newErrors = {}
      errors.forEach(e => {
        newErrors[e.propertyPath] = e.message
      })
      return newErrors
    }
  },
  computed: {
    getSuccessMessage () {
      return this.successMessage || 'Zaktualizowano stronę główną poprawnie'
    },
    getErrorMessage () {
      return this.errorMessage || 'Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem'
    }
  }
}
</script>
