<template>
<button type="button" @click.prevent="confirm">
  <slot></slot>
</button>
</template>
<script>
export default {
  props: {
    question: {type: String, required: true}
  },
  methods: {
    confirm () {
      if (window.confirm(this.question)) {
        this.$emit('click')
      }
    }
  }
}
</script>
