<template>
<div id="app" class="container-fluid">
  <div id="loader" v-show="loading">
    <img src="/img/loader.gif" alt="">
  </div>
  <top-header v-if="authenticated"></top-header>
  <div class="row">
    <div class="col-sm-2" v-if="authenticated">
      <sidebar></sidebar>
    </div>
    <div :class="{ 'col-sm-10': authenticated }">
      <router-view></router-view>
    </div>
  </div>
</div>
</template>
<script>
import Sidebar from './components/Sidebar.vue'
import TopHeader from './components/TopHeader.vue'
import auth from './auth'
import store from './store'

export default {
  name: 'App',
  data () {
    return store.state
  },
  components: {
    Sidebar,
    TopHeader
  },
  computed: {
    authenticated () {
      return auth.hasToken()
    }
  }
}
</script>
<style lang="less" type="text/less">
@import '../node_modules/bootstrap3/less/bootstrap.less';
@import './variables.less';
@import 'theme.less';
</style>
