<template>
<div>
  <form class="form-horizontal" @submit.prevent="submit">
    <fieldset>
      <legend>Dane ogólne</legend>
      <form-group label="Symbol" :error="errors['symbol']">
        <text-input v-model="instrument.symbol"></text-input>
      </form-group>
      
      <form-group label="Nazwa" :error="errors['name']">
        <text-input v-model="instrument.name"></text-input>
      </form-group>
      
      <form-group label="Nagłówek" :error="errors['header']"
                  help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
        <text-input v-model="instrument.header"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['description']">
        <textarea-input v-model="instrument.description" rows="5"></textarea-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Media</legend>
      
      <form-group label="Miniatura" :error="errors['thumbnail']">
        <media-input v-model="instrument.thumbnail"></media-input>
      </form-group>
      
      <form-group label="Zdjęcie" :error="errors['image']">
        <media-input v-model="instrument.image"></media-input>
      </form-group>
      
      <form-group label="Katalog" :error="errors['catalog']">
        <media-input v-model="instrument.catalog"></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Dodatkowe opcje</legend>
      <form-group>
        <checkbox-input v-model="instrument.idealForEnabled" label="Idealny dla" strong></checkbox-input>
        <br>
        <textarea-input v-model="instrument.idealFor" v-if="instrument.idealForEnabled" rows="5"></textarea-input>
      </form-group>
      
      <form-group>
        <checkbox-input v-model="instrument.dimensionsEnabled" label="Wymiary" strong></checkbox-input>
        <br>
        <ckeditor v-model="instrument.dimensions" v-if="instrument.dimensionsEnabled" :config="config"></ckeditor>
      </form-group>
      
      <form-group>
        <checkbox-input v-model="instrument.colorsEnabled" label="Kolory" strong></checkbox-input>
        <br>
        <image-selector v-model="instrument.colors" v-if="instrument.colorsEnabled" :images="colorsOptions"
                        multiple></image-selector>
      </form-group>
      
      <form-group>
        <checkbox-input v-model="instrument.formEnabled" label="Formularz" strong></checkbox-input>
      </form-group>
    </fieldset>
    
    <submit-button :submitting="submitting"></submit-button>
  </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import Api from './../api'
import flash from './../Flash'
import Ckeditor from 'vue-ckeditor2'
import { flatten, config } from '../helpers'

export default {
  props: {
    id: {type: [String, Number]}
  },
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api,
    Ckeditor
  },
  data () {
    return {
      submitting: false,
      config: config,
      instrument: {
        symbol: '',
        name: '',
        header: '',
        description: '',
        thumbnail: null,
        image: null,
        catalog: null,
        idealForEnabled: false,
        idealFor: '',
        dimensionsEnabled: false,
        dimensions: '',
        colorsEnabled: false,
        colors: [],
        formEnabled: false
      },
      colorsOptions: [],
      errors: []
    }
  },
  methods: {
    submit () {
      this.errors = []
      this.submitting = true
      let instrument = {...this.instrument}
      flatten(instrument, 'files', ['thumbnail', 'image', 'catalog'])
      flatten(instrument, 'colors', ['colors'])
      if (this.id) {
        Api.instruments.update(this.id, instrument).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
      } else {
        Api.instruments.post(instrument).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
      }
    },
    updateSuccess ({body}) {
      this.setData(body)
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'EditInstrument',
        params: {
          id: this.instrument.id
        }
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      Api.colors.getList().then(this.onFetchColorsSuccess)
      if (this.id) {
        Api.instruments.get(this.id).then(this.fetchSuccess)
      }
    },
    onFetchColorsSuccess ({body}) {
      this.colorsOptions = body.map(c => {
        return {
          name: c.name,
          src: c.image.filepath,
          value: c.id
        }
      })
    },
    fetchSuccess ({body}) {
      this.setData(body)
    },
    setData (body) {
      body.colors = body.colors.map(c => c.id)
      this.instrument = body
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
