<template>
<div class="viewport">
  <center-header>Logowanie</center-header>
  <p class="alert alert-danger" v-if="error">{{ error }}</p>
  <form @submit.prevent="submit">
    <form-group label="login" stacked>
      <text-input v-model="email" autofocus></text-input>
    </form-group>
    <form-group label="hasło" stacked>
      <input type="password" v-model="password" class="form-control">
    </form-group>
    <div class="text-center">
      <button class="btn btn-primary btn-lg" :disabled="logging">
        <template v-if="logging">Logowanie...</template>
        <template v-else>Zaloguj</template>
      </button>
    </div>
  </form>
</div>
</template>

<script>
import CenterHeader from './CenterHeader.vue'
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import auth from './../auth'

export default {
  data () {
    return {
      logging: false,
      email: '',
      password: '',
      error: ''
    }
  },
  components: {
    CenterHeader,
    FormGroup,
    TextInput
  },
  methods: {
    submit () {
      this.error = ''
      this.logging = true
      auth
        .login(this.email, this.password)
        .then(this.loginSuccess, this.loginError)
        .then(() => {
          this.logging = false
        })
    },
    loginSuccess () {
      window.location.reload()
    },
    loginError (message) {
      this.error = message
    }
  }
}
</script>

<style scope>
.viewport {
  margin: 0 auto;
  max-width: 340px;
  min-width: 300px;
}
</style>
