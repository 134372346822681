<template>
<div style="max-height: 200px; overflow-y: auto; position: relative;">
  <div style="position: absolute; top: 0; width: 100%; height: 20px; background-color: #ddd">
    szukaj.
  </div>
  <table class="table table-hover">
    <tbody>
    <tr v-for="option in options">
      <td>{{ option.label }}</td>
    </tr>
    </tbody>
  </table>
</div>
<!--<select class="form-control" v-model="localValue">-->
<!--<option v-for="option in options" :value="option.value">{{ option.label }}</option>-->
<!--</select>-->
</template>
<script>
export default {
  props: {
    value: {required: true},
    options: {type: Array, required: true}
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue (newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>
