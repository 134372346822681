<template>
<div>
    <form-data
            :data="data"
            :submit="submit"
    >
        <template slot-scope="props">
        <fieldset>
            <legend>Ustawienia strony głównej</legend>
            <form-group label="Slogan" :error="props.errors.slogan">
                <text-input v-model="props.data.slogan"></text-input>
            </form-group>
        </fieldset>
        </template>
    </form-data>
</div>
</template>

<script>
import FormData from './FormData.vue'
import Api from './../api'
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'

export default {
  data () {
    return {
      slug: '',
      data: {
        slogan: ''
      }
    }
  },
  components: {
    FormData,
    FormGroup,
    TextInput
  },
  methods: {
    fetchData () {
      Api.homepage.get().then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.slug = body.slug
      this.data = JSON.parse(body.json)
    },
    submit () {
      return Api.homepage.put(this.slug, {json: JSON.stringify(this.data)})
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
