<template>
<header class="row" style="line-height: 46px">
  <router-link :to="{ name: 'Dashboard' }">
    <img id="logo" src="./../assets/logo.png" alt="" height="40">
  </router-link>
  - panel administracyjny
  <button class="btn btn-primary btn-lg pull-right" @click.prevent="logout" title="Wyloguj">
    <span class="glyphicon glyphicon-off"></span>
  </button>
</header>
</template>

<script>
import auth from './../auth'

export default {
  name: 'TopHeader',
  methods: {
    logout () {
      auth.logout()
      window.location.reload()
    }
  }
}
</script>

<style lang="less" scope>
@import "../../src/variables.less";

#logo {
  margin-left: 3px;
  margin-right: 3px;
}
header {
  height: 46px;
  background-color: #fff;
  /*-moz-box-shadow: inset 0 -10px 50px -10px #ddd;*/
  /*-webkit-box-shadow: inset 0 -10px 50px -10px #ddd;*/
  /*box-shadow: inset 0 -10px 50px -10px #ddd;*/
  border-bottom: 1px solid #efefef;
}
</style>
