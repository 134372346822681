import Vue from 'vue'
import moment from 'moment'

const formatter = new Intl.NumberFormat('pl-PL', {
  style: 'currency',
  currency: 'PLN',
  minimumFractionDigits: 2
})

Vue.filter('currency', (value) => {
  return formatter.format(value)
})

Vue.filter('access', (value) => {
  if (value === 'free') {
    return 'Free'
  }
  if (value === 'premium') {
    return 'Premium'
  }
  if (value === 'pro') {
    return 'Premium + SMS'
  }
})

Vue.filter('date', (value) => {
  if (value) {
    return moment(value).format('YYYY-MM-DD')
  }
  return ''
})

Vue.filter('state', (value) => {
  if (value === 'realized') {
    return 'Zrealizowane'
  }
  if (value === 'new') {
    return 'Nowe'
  }
})
