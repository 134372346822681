<template>
<div>
  <a
    href=""
    v-for="image in images"
    @click.prevent="toggle(image.value)"
    style="display: block; margin-bottom: 20px;"
  >
    <input type="checkbox" :checked="isSelected(image.value)">
    {{ image.name }} <br>
    <img :src="image.src">
  </a>
</div>
</template>
<script>
export default {
  props: {
    value: {required: true},
    images: {type: Array, required: true},
    multiple: {type: Boolean}
  },
  computed: {
    localValue () {
      if (!(this.value instanceof Array)) {
        if (this.value) {
          return [this.value]
        }
        return []
      }
      return this.value
    }
  },
  methods: {
    isSelected (value) {
      return !!this.localValue.includes(value)
    },
    toggle (value) {
      if (this.isSelected(value)) {
        return this.unSelect(value)
      }
      return this.select(value)
    },
    select (value) {
      if (!this.multiple) {
        return this.$emit('input', value)
      }

      this.localValue.push(value)
      return this.$emit('input', this.localValue)
    },
    unSelect (value) {
      if (!this.multiple) {
        return this.$emit('input', null)
      }

      this.localValue.splice(this.localValue.indexOf(value), 1)
      return this.$emit('input', this.localValue)
    }
  }
}
</script>
