<template>
<button class="btn btn-primary" :disabled="submitting">
  <save-icon></save-icon>
  <template v-if="submitting">Zapisuję...</template>
  <template v-else>Zapisz</template>
</button>
</template>
<script>
import SaveIcon from './SaveIcon.vue'

export default {
  props: {
    submitting: {type: Boolean, required: true}
  },
  components: {
    SaveIcon
  }
}
</script>
