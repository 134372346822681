<template>
<input type="file" class="form-control" :value="value" @change="change">
</template>

<script>
export default {
  props: {
    value: {required: true}
  },
  methods: {
    change (e) {
      const files = e.target.files || e.dataTransfer.files
      console.log(files)
    }
  }
}
</script>
