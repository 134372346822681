export const apiError = () => {
  window.alert('Wystąpił nieoczekiwany błąd aplikacji, skontaktuj się z administratorem serwisu')
}
export const flatten = (object, resource, fields) => {
  fields.forEach(f => {
    if (object[f]) {
      if (Array.isArray(object[f])) {
        object[f] = object[f].map(i => {
          if (i.id) {
            return `/api/${resource}/${i.id}`
          }
          return `/api/${resource}/${i}`
        })
      } else {
        object[f] = `/api/${resource}/${object[f].id}`
      }
    }
  })
}

export const config = {
  toolbar: [
    ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
  ]
}
