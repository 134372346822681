<template>
<div style="position: absolute; top: 10px; left: 10px; right: 10px; background-color: #ddd">
  <button class="btn btn-default pull-right" @click="$emit('close')">Zamknij</button>
  <a :href="media.filepath" target="_blank">
    <img :src="media.filepath" class="img-responsive">
  </a>
</div>
</template>
<script>
export default {
  props: {
    media: {type: Object, required: true}
  }
}
</script>
