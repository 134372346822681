<template>
<div>
    <form-data
            :submit="submit"
            :data="data"
    >
        <template slot-scope="props">
        <fieldset>
            <legend>O instrumentach</legend>
            <form-group label="Nagłówek 1" :error="props.errors.firstHeader">
                <text-input v-model="props.data.firstHeader"></text-input>
            </form-group>

            <form-group label="Treść" :error="props.errors.firstContent">
                <textarea-input v-model="props.data.firstContent" rows="10"></textarea-input>
            </form-group>

            <form-group label="Nagłówek 2" :error="props.errors.secondHeader">
                <text-input v-model="props.data.secondHeader"></text-input>
            </form-group>

            <form-group label="Treść" :error="props.errors.secondContent">
                <textarea-input v-model="props.data.secondContent" rows="10"></textarea-input>
            </form-group>
        </fieldset>
        </template>
    </form-data>
</div>
</template>

<script>
import FormData from './FormData.vue'
import Api from './../api'
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import TextareaInput from './TextareaInput.vue'

export default {
  data () {
    return {
      slug: '',
      data: {
        firstHeader: '',
        firstContent: '',
        secondHeader: '',
        secondContent: ''
      }
    }
  },
  components: {
    FormData,
    FormGroup,
    TextInput,
    TextareaInput
  },
  methods: {
    submit () {
      return Api.aboutInstruments.put(this.slug, {
        json: JSON.stringify(this.data)
      })
    },
    fetchData () {
      Api.aboutInstruments.get().then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.slug = body.slug
      this.data = JSON.parse(body.json)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
