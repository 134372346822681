<template>
<div>
  <h4>Lista instrumentów</h4>
  <router-link class="btn btn-primary spaced" :to="{ name: 'AddInstrument' }">Dodaj instrument</router-link>
  
  <table class="table table-bordered table-hover table-striped">
    <thead>
    <tr>
      <th>Nazwa</th>
      <th>Pozycja</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(instrument, index) in instruments" :key="instrument.id">
      <td>{{ instrument.symbol }}</td>
      <td>
        <button
                class="btn btn-default btn-sm"
                :disabled="isLast(index)"
                @click.prevent="moveDown(instrument)"
        >
          <span class="glyphicon glyphicon-chevron-down"></span>
        </button>

        <button
                class="btn btn-default btn-sm"
                :disabled="isFirst(index)"
                @click.prevent="moveUp(instrument)"
        >
          <span class="glyphicon glyphicon-chevron-up"></span>
        </button>
      </td>
      <td>
        <router-link :to="{ name: 'EditInstrument', params: { id: instrument.id } }"
                     class="btn btn-default">edytuj</router-link>
        <question-button question="Czy na pewno usunąć instrument?" class="btn btn-danger"
                         @click="remove(instrument)">Usuń</question-button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import Api from './../api'
import InfoIcon from './InfoIcon.vue'
import QuestionButton from './QuestionButton.vue'
import PlusIcon from './PlusIcon.vue'
import SaveIcon from './SaveIcon.vue'
import MinusIcon from './MinusIcon.vue'
import {apiError} from './../helpers'

export default {
  components: {
    InfoIcon,
    QuestionButton,
    PlusIcon,
    SaveIcon,
    MinusIcon
  },
  data () {
    return {
      instruments: []
    }
  },
  methods: {
    isFirst (index) {
      return index === 0
    },
    isLast (index) {
      return index === this.instruments.length - 1
    },
    fetchData () {
      Api.instruments.getList().then(this.fetchSuccess, apiError)
    },
    fetchSuccess ({body}) {
      this.instruments = body
    },
    moveUp (instrument) {
      Api.instruments.moveUp(instrument.id).then(this.fetchData)
    },
    moveDown (instrument) {
      Api.instruments.moveDown(instrument.id).then(this.fetchData)
    },
    remove (instrument) {
      Api.instruments.remove(instrument.id).then(this.fetchData)
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  created () {
    this.fetchData()
  }
}
</script>
