<template>
<div class="form-group" :class="{ 'has-error': hasError }">
  <label class="control-label" :class="{ [labelClass]: horizontal }">{{ label }}</label>
  <div :class="controlClasses">
    <div :class="inputGroupClasses">
      <slot></slot>
      <span v-if="hasAddon" class="input-group-btn">
        <slot name="addon"></slot>
      </span>
    </div>
    <span v-if="hasError" class="help-block">{{ error }}</span>
    <span v-if="hasHelp" class="help-block">{{ help }}</span>
  </div>
</div>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    help: {type: String, default: ''},
    stacked: {type: Boolean, default: false},
    labelCol: {type: Number, default: 2},
    controlCol: {type: Number, default: 10},
    offsetCol: {type: Number},
    error: {}
  },
  computed: {
    hasHelp () {
      return !this.hasError && this.help !== ''
    },
    hasAddon () {
      return !!this.$slots.addon
    },
    horizontal () {
      return !this.stacked
    },
    labelClass () {
      return `col-sm-${this.labelCol}`
    },
    controlClass () {
      return `col-sm-${this.controlCol}`
    },
    hasError () {
      return !!this.error
    },
    controlClasses () {
      let classes = {
        [this.controlClass]: this.horizontal
      }
      if (this.offsetCol) {
        classes[`col-sm-offset-${this.offsetCol}`] = true
      }
      return classes
    },
    inputGroupClasses () {
      return {
        'input-group': this.hasAddon
      }
    }
  }
}
</script>
