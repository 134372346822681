import store from 'store'
import Api from './api'

const TOKEN = 'token'
const REFRESH_TOKEN = 'refresh_token'
const EMAIL = 'email'
const ROLES = 'roles'

export default {
  removeAuthData () {
    store.remove(TOKEN)
    store.remove(REFRESH_TOKEN)
    store.remove(EMAIL)
    store.remove(ROLES)
  },
  hasToken () {
    return !!store.get(TOKEN)
  },
  getToken () {
    return store.get(TOKEN)
  },
  hasRefreshToken () {
    return !!store.get(REFRESH_TOKEN)
  },
  getRefreshToken () {
    return store.get(REFRESH_TOKEN)
  },
  setRefreshToken (token) {
    store.set(REFRESH_TOKEN, token)
  },
  setToken (token) {
    store.set(TOKEN, token)
  },
  setEmail (email) {
    store.set(EMAIL, email)
  },
  setRoles (roles) {
    store.set(ROLES, roles)
  },
  getEmail () {
    return store.get(EMAIL)
  },
  getRoles () {
    return store.get(ROLES)
  },
  login (username, password) {
    return new Promise((resolve, reject) => {
      Api.auth.login(username, password).then(
        ({ body }) => {
          if (this.isNoAdmin(body.roles)) {
            this.removeAuthData()
            reject('Użytkownik nie posiada uprawnień administratora')
          } else {
            this.setToken(body.token)
            this.setRefreshToken(body.refresh_token)
            this.setEmail(body.email)
            this.setRoles(body.roles)
            resolve()
          }
        },
        () => reject('Nie udało się zalogować')
      )
    })
  },
  logout () {
    this.removeAuthData()
  },
  isNoAdmin (roles) {
    return !(roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN'))
  }
}
