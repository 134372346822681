<template>
<div>
  <h4>Lista aktualności</h4>
  <router-link class="btn btn-primary spaced" :to="{ name: 'AddNews' }">Dodaj aktualność</router-link>
  
  <table class="table table-bordered table-hover table-striped">
    <thead>
    <tr>
      <th>Nazwa</th>
      <th>Data</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(news, index) in news" :key="news.id">
      <td>{{ news.header }}</td>
      <td>{{ news.date }}</td>
      <td>
        <router-link :to="{ name: 'EditNews', params: { id: news.id } }"
                     class="btn btn-default">edytuj</router-link>
        <question-button question="Czy na pewno usunąć aktualność?" class="btn btn-danger"
                         @click="remove(news)">Usuń</question-button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import Api from './../api'
import InfoIcon from './InfoIcon.vue'
import QuestionButton from './QuestionButton.vue'
import PlusIcon from './PlusIcon.vue'
import SaveIcon from './SaveIcon.vue'
import MinusIcon from './MinusIcon.vue'
import {apiError} from './../helpers'

export default {
  components: {
    InfoIcon,
    QuestionButton,
    PlusIcon,
    SaveIcon,
    MinusIcon
  },
  data () {
    return {
      news: []
    }
  },
  methods: {
    fetchData () {
      Api.news.getList().then(this.fetchSuccess, apiError)
    },
    fetchSuccess ({body}) {
      this.news = body
    },
    remove (news) {
      Api.news.remove(news.id).then(this.fetchData)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
