<template>
<div class="well media-manager">
  <div style="overflow: hidden">
    <h4 class="pull-left">Biblioteka plików</h4>
    <div class="pull-right">
      <button type="button" class="btn btn-sm btn-default" @click.prevent="fetchData">Odśwież</button>
      <button type="button" @click="$emit('close')" class="btn btn-default btn-sm">&times;</button>
    </div>
  </div>
  <div>
    <label style="margin-bottom: 10px;">
        <span class="btn btn-info btn-large">
          <span class="glyphicon glyphicon-upload"></span> Dodaj plik
        </span>
      <input type="file" @change="onFileChanged" style="display: none;">
    </label>
    <p class="alert alert-danger" v-if="errors.file">{{ errors.file }}</p>
  </div>
  <div v-show="fetching">Ładowanie...</div>
  <div v-if="hasMedias" style="margin-bottom: 20px;">
    <table class="table">
      <thead>
      <tr>
        <th>Zaznaczenie</th>
        <th>Miniatura</th>
        <th>Nazwa</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="media in medias">
        <td>
          <button
            v-if="isSelected(media)"
            title="Odznacz"
            class="btn btn-primary btn-sm"
            type="button"
            @click.prevent="unSelect(media)"
          >
            <span class="glyphicon glyphicon-check"></span>
          </button>
          <button
            v-else
            title="Wybierz"
            class="btn btn-default btn-sm"
            type="button"
            @click.prevent="select(media)"
          >
            <span class="glyphicon glyphicon-unchecked"></span>
          </button>
        </td>
        <td>
          <a :href="media.filepath" target="_blank" title="Otwórz w nowym oknie">
            <img :src="media.filepath" height="50" class="img">
          </a>
        </td>
        <td>
          {{ media.filename }}
        </td>
        <td>
          <question-button
            title="Usuń z biblioteki"
            class="btn btn-danger btn-sm"
            type="button"
            @click="remove(media)"
            question="Czy na pewno usunąć plik z biblioteki?"
          >
            <span class="glyphicon glyphicon-trash"></span>
          </question-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div v-if="showEmptyMessage">
    Brak plików
  </div>
  <div>
    <button class="btn btn-primary" type="button" @click="$emit('close')">Wybierz</button>
    <button class="btn btn-default" type="button" @click="$emit('close')">Anuluj</button>
  </div>
  <large-media v-if="large" :media="large" @close="closeLarge"></large-media>
</div>
</template>
<script>
import Api from './../api'
import QuestionButton from './QuestionButton.vue'
import LargeMedia from './LargeMedia.vue'
import flash from './../Flash'

export default {
  props: {
    selected: {type: Array, required: true}
  },
  data () {
    return {
      fetching: false,
      medias: [],
      errors: {},
      large: null
    }
  },
  components: {
    QuestionButton,
    LargeMedia
  },
  methods: {
    isSelected (media) {
      return this.selected.find(m => m.id === media.id)
    },
    showLarge (media) {
      this.large = media
    },
    closeLarge () {
      this.large = null
    },
    remove (media) {
      Api.file.remove(media.id).then(this.onRemoveSuccess(media), this.onRemoveError)
    },
    onRemoveSuccess (media) {
      return () => {
        this.medias.splice(this.medias.indexOf(media), 1)
      }
    },
    onRemoveError ({body}) {
      window.alert(body.detail)
    },
    select (media) {
      this.$emit('select', media)
    },
    unSelect (media) {
      this.$emit('unselect', media)
    },
    onFileChanged (e) {
      this.upload(e.target.files)
    },
    upload (files) {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        formData.append('files[]', file)
      }
      Api.file.post(formData).then(this.onUploadSuccess, this.onUploadError)
    },
    onUploadSuccess ({body}) {
      this.medias.push(body)
    },
    onUploadError ({body}) {
      body.violations.forEach(violation => {
        this.$set(this.errors, violation.propertyPath, violation.message)
      })
    },
    fetchData () {
      this.fetching = true
      Api.file.getList().then(this.onFetchSuccess, this.onFetchError).then(() => { this.fetching = false })
    },
    onFetchSuccess ({body}) {
      this.medias = body
    },
    onFetchError () {
      flash.error('Nie udało się pobrać plików - skontaktuj się z administratorem')
    }
  },
  computed: {
    showEmptyMessage () {
      return !this.fetching && this.medias.length === 0
    },
    hasMedias () {
      return this.medias.length > 0
    }
  },
  created () {
    this.fetchData()
    document.getElementsByTagName('body')[0].classList.add('no-scroll')
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('no-scroll')
  }
}
</script>
<style>
.img:hover {
  cursor: pointer;
}
.media-manager {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 10;
  margin-bottom: 0;
}
body.no-scroll {
  overflow: hidden;
}
</style>
