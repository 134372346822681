import Vue from 'vue'

function put (slug, data) {
  return Vue.http.put(`api/contents/${slug}`, data)
}

export default {
  colors: {
    getList () {
      return Vue.http.get(`api/colors`)
    }
  },
  file: {
    remove (id) {
      return Vue.http.delete(`api/files/${id}`)
    },
    post (data) {
      return Vue.http.post(`api/files`, data)
    },
    getList () {
      return Vue.http.get(`api/files`)
    }
  },
  auth: {
    login (username, password) {
      return Vue.http.post('api/login_check', {
        _username: username,
        _password: password
      })
    }
  },
  homepage: {
    get () {
      return Vue.http.get('api/contents/homepage')
    },
    put (slug, data) {
      return put(...arguments)
    }
  },
  about: {
    get () {
      return Vue.http.get(`api/abouts/1`)
    },
    update (about) {
      return Vue.http.put(`api/abouts/1`, about)
    }
  },
  history: {
    get () {
      return Vue.http.get(`api/histories/1`)
    },
    update (history) {
      return Vue.http.put(`api/histories/1`, history)
    }
  },
  renovation: {
    get (lang) {
      return Vue.http.get(`api/renovations?lang=${lang}`)
    },
    update (id, renovation) {
      return Vue.http.put(`api/renovations/${id}`, renovation)
    }
  },
  process: {
    get (lang) {
      return Vue.http.get(`api/processes?lang=${lang}`)
    },
    update (id, process) {
      return Vue.http.put(`api/processes/${id}`, process)
    }
  },
  instruments: {
    remove (id) {
      return Vue.http.delete(`api/instruments/${id}`)
    },
    post (instrument) {
      return Vue.http.post('api/instruments', instrument)
    },
    update (id, instrument) {
      return Vue.http.put(`api/instruments/${id}`, instrument)
    },
    get (id) {
      return Vue.http.get(`api/instruments/${id}`)
    },
    getList () {
      return Vue.http.get('api/instruments')
    },
    moveUp (id) {
      return Vue.http.put(`api/instruments/${id}/move_up`)
    },
    moveDown (id) {
      return Vue.http.put(`api/instruments/${id}/move_down`)
    }
  },
  news: {
    remove (id) {
      return Vue.http.delete(`api/news/${id}`)
    },
    post (news) {
      return Vue.http.post('api/news', news)
    },
    update (id, news) {
      return Vue.http.put(`api/news/${id}`, news)
    },
    get (id) {
      return Vue.http.get(`api/news/${id}`)
    },
    getList () {
      return Vue.http.get('api/news')
    }
  },
  aboutInstruments: {
    get () {
      return Vue.http.get('api/contents/instrumenty')
    },
    put (slug, data) {
      return put(...arguments)
    }
  },
  contact: {
    get () {
      return Vue.http.get('api/contents/kontakt')
    },
    put (slug, data) {
      return put(...arguments)
    }
  },
  content: {
    get (slug) {
      return Vue.http.get(`api/contents/${slug}`)
    },
    put (slug, data) {
      return Vue.http.put(`api/contents/${slug}`, data)
    }
  }
}
