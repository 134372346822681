import $ from 'jquery'
import 'bootstrap-notify'

export default {
  success (message) {
    $.notify({
      message
    }, {
      type: 'success'
    })
  },
  error (message) {
    $.notify({
      message
    }, {
      type: 'danger'
    })
  }
}
