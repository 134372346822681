import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './components/Dashboard.vue'
import Homepage from './components/Homepage.vue'
import Contact from './components/Contact.vue'
import AboutInstruments from './components/AboutInstruments.vue'
import AddEditInstrument from './components/AddEditInstrument.vue'
import ListOfInstruments from './components/ListOfInstruments.vue'
import AddEditNews from './components/AddEditNews.vue'
import ListOfNews from './components/ListOfNews.vue'
import Renovation from './components/Renovation.vue'
import Process from './components/Process.vue'
import RenovationProcess from './components/RenovationProcess.vue'
import AboutHistory from './components/AboutHistory.vue'
import About from './components/About.vue'
import History from './components/History.vue'
import Login from './components/Login.vue'
import auth from './auth'

Vue.use(Router)

function requireAuth (to, from, next) {
  if (!auth.hasToken()) {
    next({
      name: 'Login'
    })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      name: 'Dashboard',
      path: '/',
      component: Dashboard,
      redirect: () => {
        return {
          name: 'Homepage'
        }
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (auth.hasToken()) {
          next({
            path: '/'
          })
        } else {
          next()
        }
      }
    },
    {
      path: '/homepage',
      name: 'Homepage',
      component: Homepage,
      beforeEnter: requireAuth
    },
    {
      path: '/about_instruments',
      name: 'AboutInstruments',
      component: AboutInstruments,
      beforeEnter: requireAuth
    },
    {
      path: '/instruments',
      name: 'ListOfInstruments',
      component: ListOfInstruments,
      props: true,
      beforeEnter: requireAuth
    },
    {
      path: '/instruments/add',
      name: 'AddInstrument',
      component: AddEditInstrument,
      beforeEnter: requireAuth,
      props: true
    },
    {
      path: '/instruments/:id/edit',
      name: 'EditInstrument',
      component: AddEditInstrument,
      props: true,
      beforeEnter: requireAuth
    },
    {
      path: '/news',
      name: 'ListOfNews',
      component: ListOfNews,
      props: true,
      beforeEnter: requireAuth
    },
    {
      path: '/news/add',
      name: 'AddNews',
      component: AddEditNews,
      beforeEnter: requireAuth,
      props: true
    },
    {
      path: '/news/:id/edit',
      name: 'EditNews',
      component: AddEditNews,
      props: true,
      beforeEnter: requireAuth
    },
    {
      path: '/renovation',
      component: RenovationProcess,
      children: [
        {
          path: 'renovation/:lang',
          name: 'Renovation',
          component: Renovation,
          props: true,
          beforeEnter: requireAuth
        },
        {
          path: 'process/:lang',
          name: 'Process',
          component: Process,
          props: true,
          beforeEnter: requireAuth
        }
      ]
    },
    {
      path: '/about',
      component: AboutHistory,
      children: [
        {
          path: 'about',
          name: 'About',
          component: About,
          props: true,
          beforeEnter: requireAuth
        },
        {
          path: 'history',
          name: 'History',
          component: History,
          props: true,
          beforeEnter: requireAuth
        }
      ]
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      beforeEnter: requireAuth
    }
    // {
    //   path: '/producers/add',
    //   name: 'ProducerAdd',
    //   component: ProducerAddEdit,
    //   beforeEnter: requireAuth,
    //   props: true
    // },
    // {
    //   path: '/producers/:id/edit',
    //   name: 'ProducerEdit',
    //   component: ProducerAddEdit,
    //   props: true,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/products/list',
    //   name: 'Products',
    //   component: Products,
    //   props: true,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/notices',
    //   name: 'Notices',
    //   component: Notices,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/notices/add',
    //   name: 'NoticeAdd',
    //   component: NoticeAddEdit,
    //   beforeEnter: requireAuth,
    //   props: true
    // },
    // {
    //   path: '/notices/:id/edit',
    //   name: 'NoticeEdit',
    //   component: NoticeAddEdit,
    //   props: true,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/regulations',
    //   name: 'Regulations',
    //   component: Regulations,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/price-list',
    //   name: 'PriceList',
    //   component: PriceList,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/users',
    //   name: 'Users',
    //   component: Users,
    //   beforeEnter: requireAuth
    // },
    // {
    //   path: '/users/:id/change-package',
    //   name: 'ChangePackage',
    //   component: ChangePackage,
    //   beforeEnter: requireAuth,
    //   props: true
    // },
    // {
    //   path: '/payments',
    //   name: 'Payments',
    //   component: Payments,
    //   beforeEnter: requireAuth
    // }
  ]
})
