<template>
<div>
  <form class="form-horizontal" @submit.prevent="submit">
    <fieldset>
      <legend>Dane ogólne</legend>
      
      <form-group label="Nagłówek" :error="errors['header']">
        <text-input v-model="history.header"></text-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Elementy historii</legend>
      
      <div v-for="item in history.items" style="overflow: hidden; margin-bottom: 20px;">
        <form-group label="Nagłówek" :error="errors['header']"
                    help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]">
          <text-input v-model="item.header"></text-input>
        </form-group>
        
        <form-group label="Opis" :error="errors['firstDescription']">
          <textarea-input v-model="item.content" rows="7"></textarea-input>
        </form-group>
        
        <form-group label="Tło" :error="errors['background']">
          <media-input v-model="item.background"></media-input>
        </form-group>
        
        <button type="button" class="btn btn-danger pull-right" @click="removeItem(item)">Usuń element</button>
      </div>
      
      <button type="button" class="btn btn-primary pull-right" @click="addItem" style="margin-bottom: 20px;">Dodaj element</button>
    </fieldset>
    
    <submit-button :submitting="submitting"></submit-button>
  </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import Api from './../api'
import flash from './../Flash'

export default {
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api
  },
  data () {
    return {
      history: {
        header: '',
        items: []
      },
      errors: [],
      submitting: false
    }
  },
  methods: {
    addItem () {
      this.history.items.push({
        header: '',
        content: '',
        background: null
      })
    },
    removeItem (item) {
      this.history.items.splice(this.history.items.indexOf(item), 1)
    },
    submit () {
      this.errors = []
      this.submitting = true
      let history = {...this.history}
      Api.history.update(history).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
    },
    updateSuccess ({body}) {
      this.history = body
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'History'
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      Api.history.get().then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.history = body
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
