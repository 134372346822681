import Vue from 'vue'
import VueResource from 'vue-resource'
import auth from './auth'
import store from './store'
import flash from './Flash'

Vue.use(VueResource)

Vue.http.options.root = process.env.VUE_APP_API_URL

// xdebug
// Vue.http.interceptors.push((request, next) => {
//   request.params.XDEBUG_SESSION_START = 'PHPSTORM'
//   next()
// })

Vue.http.interceptors.push((req, next) => {
  store.state.loading = true
  if (auth.hasToken()) {
    req.headers.set('Authorization', 'Bearer ' + auth.getToken())
  }
  if (req.url.includes('api') && !(req.url.includes('api/login_check') || req.url.includes('api/token/refresh'))) {
    req.headers.set('Content-Type', 'application/json')
    req.headers.set('Accept', 'application/json')
  }
  next(res => {
    store.state.loading = false
    if (res.status === 404) {
      flash.error(`Wystąpił nieoczekiwany błąd, nie odnaleziono obiektu pod adresem ${res.url} - skontaktuj się z administratorem`)
    }
    if (res.status === 500) {
      flash.error('Wystąpił nieoczekiwany błąd aplikacji, skontaktuj się z administratorem')
    }
    if (res.status === 401 && !(req.url.includes('api/login_check') || req.url.includes('api/token/refresh'))) {
      return Vue.http.post('api/token/refresh', {
        refresh_token: auth.getRefreshToken()
      }).then(({body}) => {
        auth.setToken(body.token)
        auth.setRefreshToken(body.refresh_token)
        return Vue.http[req.method.toLowerCase()](req.url, req.body)
      }, () => {
        auth.removeAuthData()
        window.location.reload()
      })
    }
  })
})
