<template>
<div class="checkbox">
  <label>
    <input type="checkbox" :checked="checked" @change="change">
    <template v-if="label">
      <template v-if="strong">
        <strong>
          {{ label }}
        </strong>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </template>
  </label>
</div>
</template>
<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    label: {type: String},
    checked: {type: Boolean, required: true},
    strong: {type: Boolean}
  },
  methods: {
    change (e) {
      this.$emit('change', e.target.checked)
    }
  }
}
</script>
