import Vue from 'vue'
import App from './App'
import {focus} from 'vue-focus'
import router from './router'
import './filters'
import './http'
import 'jquery'
import 'bootstrap3'

Vue.config.productionTip = false
Vue.config.keyCodes.plus = 107
Vue.config.keyCodes.minus = 109

Vue.directive('focus', focus)

/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
