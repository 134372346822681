<template>
<div>
  <form class="form-horizontal" @submit.prevent="submit">
    <fieldset>
      <legend>Dane ogólne</legend>
      
      <form-group label="Nagłówek" :error="errors['header']">
        <text-input v-model="about.header"></text-input>
      </form-group>
      
      <form-group label="Opis" :error="errors['description']">
        <textarea-input v-model="about.description" rows="6"></textarea-input>
      </form-group>
      
      <form-group label="Film" :error="errors['movie']">
        <text-input v-model="about.movie"></text-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Schimmel w liczbach</legend>
      
      <form-group label="Okno 1 liczby" :error="errors['statsFirstCount']">
        <text-input v-model="about.statsFirstCount"
                    help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]"></text-input>
      </form-group>
      
      <form-group label="Okno 1 treść" :error="errors['statsFirstContent']">
        <text-input v-model="about.statsFirstContent"></text-input>
      </form-group>
      
      <form-group label="Okno 2 liczby" :error="errors['statsSecondCount']">
        <text-input v-model="about.statsSecondCount"
                    help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]"></text-input>
      </form-group>
      
      <form-group label="Okno 2 treść" :error="errors['statsSecondContent']">
        <text-input v-model="about.statsSecondContent"></text-input>
      </form-group>
      
      <form-group label="Okno 3 liczby" :error="errors['statsThirdCount']">
        <text-input v-model="about.statsThirdCount"
                    help="W celu pogrubienia użyj nawiasów kwadratowych [<text>]"></text-input>
      </form-group>
      
      <form-group label="Okno 3 treść" :error="errors['statsThirdContent']">
        <text-input v-model="about.statsThirdContent"></text-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Referencje</legend>
      
      <form-group label="Zdjęcia referencji" :error="errors['references']">
        <media-input v-model="about.references" multiple></media-input>
      </form-group>
    </fieldset>
    
    <fieldset>
      <legend>Nagrody</legend>
      
      <form-group>
        <div v-for="award in about.awards" class="col-md-4" style="margin-bottom: 20px;">
          
          <form-group label="Tytuł" :error="errors['title']">
            <text-input v-model="award.title"></text-input>
          </form-group>
          
          <form-group label="Podtytuł" :error="errors['subTitle']">
            <text-input v-model="award.subTitle"></text-input>
          </form-group>
          
          <form-group label="Zdjęcie nagrody" :error="errors['image']">
            <media-input v-model="award.image" item-class="col-md-12"></media-input>
          </form-group>
          
          <button type="button" class="btn btn-danger" @click="removeAward(award)">Usuń nagrodę</button>
        </div>
        <div class="col-md-4">
          <button type="button" class="btn btn-primary" @click="addAward">Dodaj nagrodę</button>
        </div>
      </form-group>
    
    </fieldset>
    
    <submit-button :submitting="submitting"></submit-button>
  </form>
</div>
</template>
<script>
import FormGroup from './FormGroup.vue'
import TextInput from './TextInput.vue'
import FileInput from './FileInput.vue'
import MediaInput from './MediaInput.vue'
import TextareaInput from './TextareaInput.vue'
import SubmitButton from './SubmitButton.vue'
import ImageSelector from './ImageSelector.vue'
import CheckboxInput from './CheckboxInput.vue'
import TableInput from './TableInput.vue'
import Api from './../api'
import flash from './../Flash'
import Ckeditor from 'vue-ckeditor2'
import { flatten } from '../helpers'

export default {
  props: {
    lang: {type: String}
  },
  components: {
    FormGroup,
    TextInput,
    FileInput,
    MediaInput,
    TextareaInput,
    SubmitButton,
    ImageSelector,
    CheckboxInput,
    TableInput,
    Api,
    Ckeditor
  },
  data () {
    return {
      about: {
        header: '',
        description: '',
        movie: '',
        statsFirstCount: '',
        statsFirstContent: '',
        statsSecondCount: '',
        statsSecondContent: '',
        statsThirdCount: '',
        statsThirdContent: '',
        references: [],
        awards: []
      },
      errors: [],
      submitting: false
    }
  },
  methods: {
    addAward () {
      this.about.awards.push({
        title: '',
        subTitle: '',
        image: null
      })
    },
    removeAward (award) {
      this.about.awards.splice(this.about.awards.indexOf(award), 1)
    },
    submit () {
      this.errors = []
      this.submitting = true
      let about = {...this.about}
      flatten(about, 'files', ['references'])
      Api.about.update(about).then(this.updateSuccess, this.updateError).then(() => { this.submitting = false })
    },
    updateSuccess ({body}) {
      this.about = body
      flash.success('Zapisano zmiany poprawnie')
      this.$router.push({
        name: 'About'
      })
    },
    updateError ({body}) {
      body.violations.forEach(violation => {
        this.errors[violation.propertyPath] = violation.message
      })
    },
    fetchData () {
      Api.about.get().then(this.fetchSuccess)
    },
    fetchSuccess ({body}) {
      this.about = body
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
