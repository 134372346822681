<template>
<div :class="itemClass">
  <div v-if="media">
    <div class="thumbnail">
      <a :href="media.filepath" target="_blank" title="Otwórz w nowym oknie">
        <img :src="media.filepath" style="height: 100px;">
      </a>
      <div class="caption">
        <div style="margin-bottom: 10px;">
          {{ media.filename }}
        </div>
        <div style="overflow: hidden">
          <button class="btn btn-default pull-left btn-sm" type="button" @click="$emit('change')">Zmień</button>
          <button class="btn btn-danger pull-right btn-sm" type="button" @click="$emit('delete', media)">Usuń</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    media: {required: true},
    itemClass: {default: 'col-md-4'}
  }
}
</script>
